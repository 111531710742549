let getUrl = window.location;

let apiLink = getUrl .protocol + "//" + getUrl.host + "/" + "dashboard_disp/";
let loginLink = getUrl .protocol + "//" + getUrl.host + "/api/";

if (process.env.REACT_APP_DEBUG === "true") {
  apiLink = "http://127.0.0.1:80/dashboard_disp/";
  loginLink = "http://127.0.0.1:80/api/"
}

const setHeaders = () => {
  let headers = new Headers()
  //headers.append('Authorization', 'Basic' + base64.encode(username + ":" + password));
  headers.set('Authorization', 'Basic ' + btoa(localStorage.getItem("username") + ":" + localStorage.getItem("password")));

  return headers;
}

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  }
  let json = response.json()
  return json.then(Promise.reject.bind(Promise))
}

export const getDevices = () => {
  const headers = setHeaders()
  return fetch(apiLink + "getdashboard", {
    method: "GET",
    credentials: "same-origin",
    headers: headers
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error('loadDisplay returned an error:', res)
      return Promise.reject(new Error('loadDisplay returned an error'))
    })
}


export const sendRebootCommand = (hwid) => {
  const headers = setHeaders()
  const body = new window.FormData()
  body.append('device_id', hwid)
  body.append('command', 'RESTART_DEVICE')
  return fetch(apiLink + "add_command_device", {
    method: "POST",
    credentials: "same-origin",
    headers: headers,
    body
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error('loadDisplay returned an error:', res)
      return Promise.reject(new Error('loadDisplay returned an error'))
    })
}

export const login = (username, password) => {

  const headers = setHeaders()
  const body = new window.FormData()
  body.append('username', username)
  body.append('password', password)
  return fetch(loginLink + "login", {
    method: "POST",
    credentials: "same-origin",
    headers: headers,
    body
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.status
      console.error('loadDisplay returned an error:', res)
      return Promise.reject(new Error('loadDisplay returned an error'))
    })
}

export const logout = () => {
  const headers = setHeaders()
  return fetch(loginLink + "logout", {
    method: "GET",
    credentials: "same-origin",
    headers: headers
  })
    .then(checkStatus)
    .then(res => {
      if (res.status === 200) return res.json()
      console.error('logout returned an error:', res)
      return Promise.reject(new Error('logout returned an error'))
    })
}





