import './App.css';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';

import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

function App() {
  return (
    <div >
      <Router>
        <Switch>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/">
            <Login />
          </Route>

        </Switch>
      </Router>
    </div>
  );
}

export default App;
