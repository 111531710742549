import React from 'react';
import logo from '../images/imok-logo.png';
import { TextBoxComponent, TicksData } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {Redirect} from "react-router-dom";
import {login} from '../api/Api'

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          username: "",
          password: "",
          redirect: false,
          hidden: "password"
        };
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
    
      }
    
      //Password
      handleChangePassword(event) {
        this.setState({ password: event.target.value });
      }
    
      //Cahnge Username
      handleChangeUsername(event) {
        this.setState({ username: event.target.value });
      } 

      login = (e) => {
          e.preventDefault()

          login(this.state.username, this.state.password)
          .then((response => {
              console.log(response)
              if (response === 200) {
                  localStorage.setItem("username", this.state.username)
                  localStorage.setItem("password", this.state.password)
                  this.setState({redirect: true})
              }
              else {
                  alert("Invalid Credentials")
              }
          }))
      }

      showPassword = () => {
          if (this.state.hidden === 'password') {
              this.setState({hidden: 'text'})
          }
          else {
              this.setState({ hidden: 'password'})
          }
      }



    render() {

        const { redirect } = this.state;

        if (redirect) {
          return <Redirect to='/dashboard'/>;
        }

        return (
            <div className="center">
                <img src={logo} alt={"imok-logo"}></img>
                <form>
                <div className="login">
                <div className="username">
                <TextBoxComponent placeholder="Username" floatLabelType="Never" value={this.state.username} onChange={this.handleChangeUsername} />
                </div>
                <div className="username">
                <TextBoxComponent placeholder="Password" floatLabelType="Never" type={this.state.hidden} value={this.state.password} onChange={this.handleChangePassword}/>
                <span className="field-icon toggle-password" onClick={this.showPassword}>Show</span>
                </div>
                <div>
                <ButtonComponent cssClass="login-button" onClick={this.login}>Accedi</ButtonComponent>
                </div>
                </div>
                </form>
            </div>
        )
    }
}

export default Login;