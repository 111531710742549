import React from 'react';
import {Redirect} from "react-router-dom";

import logoWhite from '../images/imokdasthboard.png';
import logoutImg from '../images/logout.png'

class Navbar extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            errors: 0,
            redirect: false
        }
    }

    componentDidMount() {
        setTimeout(this.countErrors.bind(this), 1000);
        setInterval(this.countErrors.bind(this), 180000);
    }

    handleLogout = () => {
        localStorage.removeItem("username")
        localStorage.removeItem("password")
        this.setState({redirect: true})
    }

    countErrors = () => {
        let errors = 0;
        this.props.devices.forEach((device => {
            if (device.error === true) {
                errors++
            }
        }))
        this.setState({errors: errors})
    }

    render() {
        const { errors, redirect } = this.state

        if (redirect) {
            return <Redirect to='/'/>;
        }

        return (
            <div>
                <nav className="NavbarItems">
                    <img src={logoWhite} alt="imok-logo" className="navbar-logo"></img>
                    <div className="rounded-rectangle">
                        <p className="error-b">Error: <span className="error-span">{errors}</span> / {this.props.devices.length}</p>
                        <p className="no-error">No Error: <span className="no-error-span">{this.props.devices.length - errors}</span> / {this.props.devices.length}</p>
                    </div>
                    <div className="logout" onClick={this.handleLogout}>
                    <p className="logout-text">Logout</p> 
                    <img src={logoutImg} alt="logout" className="logout-image"></img>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Navbar;