import React from 'react'
import { getDevices, sendRebootCommand } from '../api/Api';
import loading from '../images/Symbolwait.gif';

import {
    GridComponent,
    ColumnDirective,
    ColumnsDirective,
    Sort,
    Inject,
    Toolbar,
    DetailRow,
    Page,
    Filter,
} from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Navbar from '../components/Navbar';



class Dashboard extends React.Component {


    constructor(props) {
        super(props)

        this.state = {
            devices: []
        }

        this.toolbarOptions = ['Search'];

        this.fetchDevices()

        setInterval(this.fetchDevices.bind(this), 240000);

        this.rebootTemplate = this.rebootTemplate.bind(this);

    }

    filterOptions = {
        type: 'CheckBox',
    }

    fetchDevices = () => {
        getDevices()
            .then((res => {
                console.log(res)
                this.setState({ devices: res })
            }))
    }

    gridTemplate(props) {
        const src = props.error;

        if (src === true) {
            return (
                <div className="red-dot" />
            )
        }
        else if (src === "reboot") {
            return (
                <img src={loading} className="rebooting"></img>
            )
        }
        return (
            <div className="green-dot" />
        )
    }

    rebootTemplate(props) {
        return (<ButtonComponent cssClass="reboot" onClick={() => this.onReboot(props)}>REBOOT</ButtonComponent>)
    }

    onReboot = (props) => {
        let devices = this.state.devices
        let index = this.state.devices.findIndex((element => {
            return element.hwid === props.hwid
        }))
        devices[index].error = "reboot"
        this.setState({ devices: devices })
        sendRebootCommand(props.device_id)

        this.grid.refresh()

    }

    detailTemplate(props) {
        let src = props.screenshot;
        console.log(src)
        return (<table className="detailTable" style={{ width: "100%" }}>
            <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
            </colgroup>
            <tbody>
                <tr>
                    <th className="detailTableTd">Screen</th>
                    <th className="detailTableTd">CPU</th>
                    <th className="detailTableTd">Memory</th>
                    <th className="detailTableTd">Problems</th>
                </tr>
                <tr>
                    <td className="detailTableTd">
                        <img src={src} alt={"Missing Screenshot"} className="screenshot" onClick={() => window.open(src)} />
                    </td>
                    <td className="detailTableTd">
                        {props.cpuPercentage}
                    </td>
                    <td className="detailTableTd">
                        {props.freeMemory}
                    </td>
                    <td className="detailTableTd">
                        {props.problems === null ? <div></div> :
                            <div>
                                {props.problems[0]}
                                <br />
                                {props.problems[1]}
                                <br />
                                {props.problems[2]}
                                <br />
                            </div>
                        }
                    </td>

                </tr>
            </tbody>
        </table>);
    }

    render() {

        return (
            <div>
                <Navbar devices={this.state.devices} />
                <GridComponent
                    ref={g => this.grid = g}
                    dataSource={this.state.devices}
                    allowSorting={true}
                    allowFiltering={true}
                    filterSettings={this.filterOptions}
                    toolbar={this.toolbarOptions}
                    detailTemplate={this.detailTemplate}
                    allowPaging={true}
                    pageSettings={{ pageSize: 20 }}>
                    <ColumnsDirective>
                        <ColumnDirective field='hwid' headerText="ID Beelink" width='280' allowFiltering={false} />
                        <ColumnDirective field="contract" headerText="Contract" allowFiltering={false} />
                        <ColumnDirective field="site_name" headerText="Site" allowFiltering={false} />
                        <ColumnDirective field="restaurant" headerText="Restaurant" allowFiltering={false} />
                        <ColumnDirective field="displayID" headerText="DisplayID" allowFiltering={false} />
                        <ColumnDirective field="error" headerText="Error" template={this.gridTemplate} />
                        <ColumnDirective field="problem" headerText="Problem" allowFiltering={false} />
                        <ColumnDirective headerText="Problem" template={this.rebootTemplate} allowFiltering={false} />
                    </ColumnsDirective>
                    <Inject services={[Sort, Toolbar, DetailRow, Page, Filter]} />
                </GridComponent>
            </div>
        )
    }
}

export default Dashboard;